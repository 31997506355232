import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldLocationAutocompleteInput,
} from '../../../components';
import PasswordStrengthBar from 'react-password-strength-bar';

import css from './BusinessSignupForm.module.css';

const identity = v => v;

const BusinessSignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        values,
      } = fieldRenderProps;
      const t = intl.formatMessage;

      // email
      const emailRequired = validators.required(
        t({
          id: 'BusinessSignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        t({
          id: 'BusinessSignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = t({
        id: 'BusinessSignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = t(
        {
          id: 'BusinessSignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = t(
        {
          id: 'BusinessSignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordSpecialCharacters = validators.passwordSpecialCharacters(
        t({
          id: 'BusinessSignupForm.passwordSpecialCharacters',
        })
      );
      const passwordContainsNumber = validators.passwordContainsNumber(
        t({
          id: 'BusinessSignupForm.passwordContainsNumber',
        })
      );
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength,
        passwordSpecialCharacters,
        passwordContainsNumber
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <p className={css.businessSignupDesc}>
            <FormattedMessage id="AuthenticationPage.business.signupDescription" />
          </p>
          <FieldTextInput
            type="hidden"
            id={formId ? `${formId}.company` : 'company'}
            name="company"
          />
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={t({
                id: 'BusinessSignupForm.emailLabel',
              })}
              placeholder={t({
                id: 'BusinessSignupForm.emailPlaceholder',
              })}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={t({
                  id: 'BusinessSignupForm.firstNameLabel',
                })}
                placeholder={t({
                  id: 'BusinessSignupForm.firstNamePlaceholder',
                })}
                validate={validators.required(
                  t({
                    id: 'BusinessSignupForm.firstNameRequired',
                  })
                )}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={t({
                  id: 'BusinessSignupForm.lastNameLabel',
                })}
                placeholder={t({
                  id: 'BusinessSignupForm.lastNamePlaceholder',
                })}
                validate={validators.required(
                  t({
                    id: 'BusinessSignupForm.lastNameRequired',
                  })
                )}
              />
            </div>
            <FieldTextInput
              className={css.displayName}
              type="displayName"
              id={formId ? `${formId}.displayName` : 'displayName'}
              name="displayName"
              autoComplete="displayName"
              label={t({
                id: 'BusinessSignupForm.displayNameLabel',
              })}
              placeholder={t({
                id: 'BusinessSignupForm.displayNamePlaceholder',
              })}
              validate={validators.required(
                t({
                  id: 'BusinessSignupForm.displayNameRequired',
                })
              )}
            />
            <FieldTextInput
              className={css.registrationNumber}
              type="registrationNumber"
              id={formId ? `${formId}.registrationNumber` : 'registrationNumber'}
              name="registrationNumber"
              autoComplete="registrationNumber"
              label={t({
                id: 'BusinessSignupForm.registrationNumberLabel',
              })}
              placeholder={t({
                id: 'BusinessSignupForm.registrationNumberPlaceholder',
              })}
              validate={validators.required(
                t({
                  id: 'BusinessSignupForm.registrationNumberRequired',
                })
              )}
            />
            <FieldLocationAutocompleteInput
              rootClassName={css.legalAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="legalAddressRaw"
              label={t({ id: 'BusinessSignupForm.legalAddressLabel' })}
              placeholder={t({ id: 'BusinessSignupForm.legalAddressPlaceholder' })}
              useDefaultPredictions={false}
              format={identity}
              validate={validators.composeValidators(
                validators.autocompleteSearchRequired(
                  t({ id: 'BusinessSignupForm.legalAddressRequired' })
                ),
                validators.autocompletePlaceSelected(
                  t({ id: 'BusinessSignupForm.legalAddressNotRecognized' })
                )
              )}
            />
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={t({
                id: 'BusinessSignupForm.passwordLabel',
              })}
              placeholder={t({
                id: 'BusinessSignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
            <PasswordStrengthBar
              password={values.password}
              minLength={validators.PASSWORD_MIN_LENGTH}
            />
          </div>

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="BusinessSignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

BusinessSignupFormComponent.defaultProps = { inProgress: false };

BusinessSignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BusinessSignupForm = compose(injectIntl)(BusinessSignupFormComponent);
BusinessSignupForm.displayName = 'BusinessSignupForm';

export default BusinessSignupForm;
